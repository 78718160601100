import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby";
import {
  Header
} from 'semantic-ui-react'
import {
  Configure,
  connectStateResults,
  Hits,
  InstantSearch,
  Pagination,
  SearchBox,
  Snippet,
  SortBy
} from 'react-instantsearch-dom';

import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';


const News = () => {
  const searchClient = instantMeiliSearch(
    "https://admin.curranindex.org/data",
    "",
    {
      finitePagination: true,
      paginationTotalHits: 9999,
      primaryKey: 'id',
    }
  );

  const Hit = ({ hit }) => (
      <Link to={`/news/${hit.id}/`}>
        <div style={essayPanelStyle}>
        <div style={titleStyle}> {hit.title} </div>
        <div style={hit.title ? subtitleStyle : titleStyle}> {getMonthString(hit.pub_year, hit.pub_month)}, {hit.pub_year} </div>
          <div style={textStyle}>
            <Snippet hit={hit} attribute="html_safe_contents" /> {hit.html_safe_contents.length >= 500 && <span style={readMoreLabel}>... Read more</span>}
          </div>
        </div>
      </Link>
  )

  const SearchResultsCount = ({ searchResults }) => (
    <div style={resultsCountStyle}>
      { searchResults &&
        <h4>{ (searchResults.nbHits).toLocaleString() } results</h4>
      }
      <SortBy
        defaultRefinement="essays:pub_year:desc"
        items={[
          { label: 'Sort by Year (Descending)', value: 'essays:pub_year:desc' },
          { label: 'Sort by Year (Ascending)', value: 'essays:pub_year:asc' }
        ]}
      />
    </div>
  );

  const DisplaySearchResultsCount = connectStateResults(SearchResultsCount);

  const getMonthString = (yearInt, monthInt) => {
    const date = new Date(yearInt, monthInt -1);
    return date.toLocaleString('default', { month: 'long' }) || "";
  }

  const essayPanelStyle = {
    padding: "20px",
    border: "1px solid #CCC",
    borderRadius: "4px",
    boxShadow: "0 1px 1px rgb(0 0 0 / 5%)",
    marginBottom: "20px",
    color: "black"
  }

  const titleStyle = {
    fontSize: "1.25em",
    marginBottom: "10px"
  }

  const subtitleStyle = {
    fontSize: "1em",
    marginBottom: "10px"
  }

  const textStyle = {
    fontSize: "1em"
  }

  const readMoreLabel = {
    color: "#337ab7"
  }

  const resultsCountStyle = {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 10px"
  }

  return (
    <Layout>
      <Seo title="News" />
      <InstantSearch
        indexName={"essays"}
        searchClient={searchClient}
      >
        <Configure
          attributesToSnippet={['html_safe_contents:500']}
        />
        <div align="center">&nbsp;</div>
        <div>
          <Header as="h2">Search Essays</Header>
          <SearchBox
            searchAsYouType={false}
          />
          <DisplaySearchResultsCount />
          <div className="results">
            <Hits className={"list"} hitComponent={Hit} />
            <Pagination />
          </div>
        </div>
      </InstantSearch>
    </Layout>
  )
}

export default News
